<template>
  <div class="insider-tip">
    <RouterLink class="insider-tip__link" :to="routeTo">
      <div class="data-item__img insider-tip__image" :style="`background-image:url(${mediaUrl})`" />
      <div v-if="item.title" class="category-head__title insider-tip__title">{{ item.title }}</div>

      <div class="d-flex insider-tip__subcontent">
        <div v-if="item.author" class="small-grey">{{ item.author }},</div>
        <div v-if="item.author_position" class="small-grey ml-2">{{ item.author_position }}</div>
      </div>
    </RouterLink>
  </div>
</template>

<script>
import { getEntityRoute } from '@/helpers/entityType';
import ImageHandler from '@/helpers/ImageHandler';

export default {
  name: 'DestinationInsiderTipItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    mediaUrl() {
      return new ImageHandler().getFirstImgFromMedia(this.item);
    },
    routeTo() {
      return getEntityRoute(this.item);
    },
  },
};
</script>

<style lang="scss" scoped>
.insider-tip {
  margin-bottom: 30px;

  @media (min-width: 768px) {
    margin-bottom: 40px;
  }

  &__image {
    margin-bottom: 10px;
    transition: all 0.3s linear;

    @media (min-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__link:hover &__image {
    transform: scale(0.97);
  }

  &__title {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.85px;

    @media (min-width: 768px) {
      font-size: 22px;
      line-height: 30px;
    }
  }

  &__subcontent {
    .small-grey {
      font-size: 14px;
      line-height: 20px;

      @media (min-width: 768px) {
        font-size: 18px;
        line-height: 25px;
      }
    }
  }
}
</style>
