import { render, staticRenderFns } from "./DestinationInsiderTipItem.vue?vue&type=template&id=79aaaddc&scoped=true&"
import script from "./DestinationInsiderTipItem.vue?vue&type=script&lang=js&"
export * from "./DestinationInsiderTipItem.vue?vue&type=script&lang=js&"
import style0 from "./DestinationInsiderTipItem.vue?vue&type=style&index=0&id=79aaaddc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79aaaddc",
  null
  
)

export default component.exports